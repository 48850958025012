
import axios from "@/utils/axios";
// import moment from 'moment';

const solicitarCodigoAcessoIFOOD = async () => {
  try {
    return (await axios.get('ifood/solicitarCodigoAcesso')).data;
  }
  catch (e) {
    console.log("ERRO NO solicitarCodigoAcessoIFOOD", e)
  }
}

const getDadosLoja = async (cod_empresa, integration_id) => {
  try {
    return (await axios.post('ifood/dadosLoja', { cod_empresa, integration_id })).data;
  } catch (error) {
    console.log("ERRO ON GEtdadosLoja", error);
  }
}
const getConfirmacaoAcesso = async (authorizationCode, authorizationCodeVerifier) => {
  try {
    return (await axios.post('ifood/confirmacaoAcesso', { authorizationCode, authorizationCodeVerifier })).data;
  } catch (error) {
    console.log("ERRO ON GEtdadosLoja", error);
  }
}

const atualizarDadosEmpresa = async (cod_empresa, integration_id,dados) => {
  try {
    return (await axios.post('ifood/atualizarDadosEmpresa', { cod_empresa, integration_id,dados })).data;
  } catch (error) {
    console.log("ERRO ON atualizarDadosEmpresa", error);
  }
}

const alterarStatusLoja = async (cod_empresa, integration_id, status) => {
  try {
    return (await axios.post('ifood/alterarStatusLoja', { cod_empresa, integration_id, status })).data;
  } catch (error) {
    console.log("ERRO ON alterarStatusLoja", error);
  }
}

const getCatalogs = async (cod_empresa, integration_id) => {
  try {
    return (await axios.post('ifood/getCatalogs', { cod_empresa, integration_id })).data;
  } catch (error) {
    console.log("ERRO ON getCatalogs", error);
  }
}

const updateIntegration = async (cod_empresa, integration_id,dados) => {
  try {
    return (await axios.post('ifood/updateIntegration', { cod_empresa, integration_id, dados })).data;
  } catch (error) {
    console.log("ERRO ON getCatalogs", error);
  }
}
//

export default {
  solicitarCodigoAcessoIFOOD,  
  getConfirmacaoAcesso,
  getDadosLoja,
  atualizarDadosEmpresa,
  alterarStatusLoja,
  getCatalogs,
  updateIntegration
}