<template>
  <div>
    <empresa-combo
      :selecionar="changeEmpresa"
      :noStyle="false"
      :comTodos="false"
      :selected="empresaSelecionada.cod_empresa"
    />
    <div class="d-flex justify-content-between mt-2">
      <div>
        <h2 class="mt-1 text-left pl-3 mt-3"><b-icon-house /> Lojas</h2>
        <b-link class="text-left"
          ><small @click="atualizarDadosEmpresa"
            ><i class="fa fa-recycle mr-1" /> Atualizar</small
          ></b-link
        >
      </div>
      <div
        class="btnAdd"
        @click="openForm()"
      >
        <b-icon-plus /> Integração
      </div>
    </div>

    <hr />
    <div
      class="text-center p-5"
      v-if="!lista || lista.length <= 0"
    >
      Nenhuma loja cadastrada ainda. :(
    </div>
    <div class="row">
      <div
        class="col-12 mt-4"
        v-for="(loja, idx) in lista"
        :key="`loja_iff_${idx}`"
      >
        <b-overlay
          :show="false === true"
          rounded="sm"
          @click="loja.loading = false"
        >
          <b-card class="text-left">
            <b-card-title v-if="loja && loja.Adicionais">
              <div class="d-flex justify-content-between">
                <div class="d-">
                  <span>
                    <i class="fa fa-laptop-house" />
                    {{ loja.Adicionais["NomeLoja"] || "Loja" }}
                    <div style="font-size: 8px">
                      {{ loja.Adicionais["IdLoja"] }}
                    </div>
                    <div
                      class="py-4 px-4 bg-light"
                      v-if="
                        loja.Adicionais['MsgStatus'] &&
                        loja.Adicionais['MsgStatus'] != ''
                      "
                    >
                      <b-icon-info-circle /> {{ loja.Adicionais["MsgStatus"] }}
                    </div>
                  </span>
                  <span
                    v-if="loja.Adicionais['Funcionamento']"
                    class="ml-4"
                  >
                    <small>
                      <small>
                        <b-icon-clock />
                        {{ loja.Adicionais["Funcionamento"] }}
                      </small>
                    </small>
                  </span>
                </div>
                <div class="text-center">
                  <b-btn variant="danger" @click="reauth(loja)" v-if="loja && loja.Adicionais&&loja.Adicionais['statusAPI']&&loja.Adicionais['statusAPI']=='unconnected'">
                    <b-icon-lock/>Re-autenticar
                  </b-btn>
                  <div
                    v-if="
                      !loja.Adicionais['error'] ||
                      loja.Adicionais['error'] == ''
                    "
                  >
                    <div class="w-100 text-center">
                      <b-checkbox
                        class="mr-auto"
                        switch
                        title="Integraão Ativa"
                        v-model="loja.Ativo"
                        @change="changeInfos(loja)"
                      >
                        Ativo</b-checkbox
                      >
                    </div>
                    <div>
                      <b-dropdown
                        class="mt-1"
                        block
                        :variant="
                          loja.Adicionais['Status'] == 'Aberta'
                            ? 'success'
                            : 'danger'
                        "
                      >
                        <template #button-content>
                          <b-icon-check-circle
                            v-if="loja.Adicionais['Status'] == 'Aberta'"
                          />
                          <b-icon-x-circle
                            v-if="loja.Adicionais['Status'] == 'Fechada'"
                          />
                          Loja
                          {{ loja.Adicionais["Status"] }}
                        </template>
                        <b-dropdown-item
                          variant="success"
                          @click="alterarStatusLoja('OPEN', loja)"
                          ><b-icon-check-circle /> Aberta</b-dropdown-item
                        >
                        <b-dropdown-item
                          variant="danger"
                          @click="alterarStatusLoja('INTERRUPT', loja)"
                          ><b-icon-x-circle /> Fechada</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                    <b-dropdown
                      block
                      class="mt-1"
                      :variant="
                        loja.Adicionais['Automatico'] == 'true'
                          ? 'success'
                          : 'danger'
                      "
                    >
                      <template #button-content>
                        <b-icon-check-circle
                          v-if="loja.Adicionais['Automatico'] == 'true'"
                        />
                        <b-icon-x-circle
                          v-if="loja.Adicionais['Automatico'] == 'false'"
                        />

                        {{
                          loja.Adicionais["Automatico"] == "true"
                            ? "Importação Automática"
                            : "Importação Manual"
                        }}
                      </template>
                      <b-dropdown-item
                        variant="success"
                        @click="statusImportacao('true', loja)"
                        ><b-icon-check-circle /> Ativar importação
                        automática</b-dropdown-item
                      >
                      <b-dropdown-item
                        variant="danger"
                        @click="statusImportacao('false', loja)"
                        ><b-icon-x-circle /> Ativar importação
                        MANUAL</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </b-card-title>
            <div
              class="py-4 px-4 bg-light text-danger text-center"
              v-if="loja.Adicionais['error'] && loja.Adicionais['error'] != ''"
            >
              <b-icon-exclamation-circle-fill style="font-size: 40px" /><br />
              {{ loja.Adicionais["error"] }}
            </div>
            <div
              class="d-flex justify-content-between"
              v-if="!loja.Adicionais['error'] || loja.Adicionais['error'] == ''"
            >
              <b-link @click.prevent="abrirCardapio(loja)"
                ><b-icon-book-half /> Cardápio</b-link
              >
              <b-link class="ml-2"><b-icon-cart /> Pedidos</b-link>
              <div v-if="loja.loading">
                <b-spinner small></b-spinner>
                atualizando...
              </div>
            </div>
          </b-card>
        </b-overlay>
      </div>
      <div class="col-12 p-6 mt-4"></div>
    </div>
    <b-modal
      id="modal-cardapio"
      size="xl"
      title="Cardápio"
      hide-footer
    >
      <div
        v-for="catalog in cardapio"
        :key="`Cardapio$_${catalog.catalogId}`"
      >
        <b>Tipo: {{ catalog.context == "DEFAULT" ? "Normal" : "Retirar" }}</b>
        <hr />
        <div
          v-for="categorie in catalog.categories"
          :key="`Categories$_${catalog.catalogId}_${categorie.id}`"
          class="pl-3"
        >
          <h3>{{ categorie.name }}</h3>
          <hr />
          <b-table
            :items="categorie.items"
            hover
            :fields="[
              { key: 'image', label: '' },
              { key: 'externalCode', label: 'Codigo', sortable: true },
              { key: 'name', label: 'Produto', sortable: true },
              { key: 'description', label: 'Descrição', sortable: true },
              { key: 'price', label: 'Preço', sortable: true },
            ]"
            small
            style="font-size: 10px"
          >
            <template #cell(image)="row">
              <div
                class="text-left"
                style="width: 100px"
              >
                <img
                  :src="row.item.imagePath"
                  style="max-width: 100px"
                />
              </div>
            </template>
            <template #cell(price)="row">
              {{ row.item.price.value | currency }}
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-integracao"
      hide-footer
      title="Configurar Integração"
    >
      <div v-if="integracaoSelecioanda">
        <b-card title="ID da loja">
          <b-input v-model="integracaoSelecioanda.Adicionais.IdLoja" />
          <b-btn
            variant="primary"
            class="mt-2"
            @click="solicitarChaveIfood"
            :disabled="
              !integracaoSelecioanda.Adicionais.IdLoja ||
              integracaoSelecioanda.Adicionais.IdLoja.length < 36
            "
            >Solicitar Acesso</b-btn
          >
        </b-card>
        <b-card
          title="Chave de Autorização"
          class="mt-4"
          v-if="integracaoSelecioanda.Adicionais.Solicitado"
        >
          <b-input
            v-model="integracaoSelecioanda.Login"
            maxlength="9"
            max="9"
            :disabled="integracaoSelecioanda.Adicionais.autorizado"
          />
          <div class="d-flex justify-content-between">
            <b-btn
              variant="success"
              class="mt-2"
              :disabled="
                integracaoSelecioanda.Adicionais.autorizado ||
                loadingAutorizacaoIfood ||
                !integracaoSelecioanda.Login ||
                integracaoSelecioanda.Login.length < 9 ||
                !integracaoSelecioanda.Adicionais.authorizationCodeVerifier
              "
              @click="autorizarChaveIfood"
            >
              <span v-if="loadingAutorizacaoIfood">
                <b-spinner small></b-spinner>
              </span>

              Autorizar
            </b-btn>
            <div
              class="text-center text-success p-3"
              v-if="integracaoSelecioanda.Adicionais.autorizado"
            >
              <b-icon-check-circle-fill style="font-size: 40px" /><br />
              Autorizado com sucesso!
              <b-btn @click="updateIntegrations">salvar</b-btn>
            </div>
          </div>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>
import EmpresaCombo from "../../components/empresa/EmpresaCombo.vue";
import IFoodLib from "../../libs/IFoodLib";

export default {
  components: { EmpresaCombo },
  props: {},
  mounted() {
    //this.atualizarDadosEmpresa();
    this.carrega();
    this.timeToUpdate = setInterval(() => {
      console.log("atualizarDadosEmpresa");
      this.atualizarDadosEmpresa();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.timeToUpdate);
  },
  unmounted() {
    clearInterval(this.timeToUpdate);
  },
  data() {
    return {
      lista: [],
      cardapio: null,
      timeToUpdate: null,
      empresaSelecionada: this.$store.state.empresa_ativa,
      empresas: [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ],
      integracaoSelecioanda: null,
      loadingAutorizacaoIfood: false,
    };
  },
  watch: {
    "$store.state.empresa_ativa": {
      deep: true,
      handler() {
        this.empresas = [
          this.$store.state.empresas,
          ...this.$store.state.empresas.empresas,
        ];
        this.empresaSelecionada = JSON.parse(
          JSON.stringify(
            this.empresas.find(
              (x) => x.cod_empresa == this.empresaSelecionada.cod_empresa
            )
          )
        );
        this.$forceUpdate();
        this.carrega();
      },
    },
  },
  computed: {},
  methods: {
    reauth(loja){
      loja.Adicionais.autoriazado = false;
      this.openForm(loja)
    },
    openForm(integracao) {
      if (integracao) {
        this.integracaoSelecioanda = integracao;
      } else {
        this.integracaoSelecioanda = {
          Login: "",
          Ativo: true,
          Sistema: "IFood",
          Tipo: "Delivery",
          Token: "",
          Adicionais: {
            NomeLoja: "",
            IdLoja: "",
            Solicitado: false,
            Automatico: false,
            Status: "Aberta",
            cod_caixa: "1",
          },
        };
      }
      this.$bvModal.show("modal-integracao");
    },
    async abrirCardapio(loja) {
      loja.loading = true;
      if (loja) {
        try {
          let res = await IFoodLib.getCatalogs(
            this.$store.state.empresa_ativa.cod_empresa,
            loja.id
          );
          if (res && res.success) {
            this.cardapio = res.data;
            this.$bvModal.show("modal-cardapio");
          } else {
            alert(res.message);
          }
        } catch (error) {
          console.log("Error on abrirCardapio: ", error);
        }
      }
      loja.loading = false;
    },
    async alterarStatusLoja(status, loja) {
      //                      loja.Adicionais.find((x) => x.chave == 'Status').valor =
      //                'Aberta'

      loja.loading = true;
      this.$forceUpdate();

      setTimeout(async () => {
        this.$nextTick(async () => {
          try {
            let res = await IFoodLib.alterarStatusLoja(
              this.$store.state.empresa_ativa.cod_empresa,
              loja.id,
              status
            );
            console.log("alterarStatusLoja", res);

            if (res && res.message) {
              this.$swal({
                title: res.message,
                icon: res.success ? "success" : "error",
                toast: true,
                timer: 2000,
                position: "top-end",
                showConfirmButton: false,
              });
            }

            if (res && res.success) {
              setTimeout(async () => {
                await this.atualizarDadosEmpresa();
                loja.loading = false;
              }, 5000);
            } else {
              loja.loading = false;
            }
          } catch (error) {
            console.log("error on alterar status", error);
            loja.loading = false;
          }
        });
      }, 300);
    },
    async statusImportacao(status, loja) {
      console.log(loja);
      this.$store.state.empresa_ativa.integracoes
        .find((x) => x.id === loja.id)
        .Adicionais.find((x) => x.chave == "Automatico").valor = status;
      this.atualizarDadosEmpresa(true);
    },
    async atualizarDadosEmpresa(withDados = false) {
      console.log("ATUALIZA DADOS EMPRESA");
      this.lista = this.$store.state.empresa_ativa.integracoes.filter(
        (x) => x.Sistema == "IFood"
      );
      for (let loja of this.lista) {
        loja.loading = true;
        let dt = JSON.parse(JSON.stringify(this.$store.state.empresa_ativa));
        delete dt.empresas;
        delete dt.loading;
        console.log(
          "sand",
          this.$store.state.empresa_ativa.cod_empresa,
          loja.id,
          withDados ? dt : null
        );
        try {
          let res = await IFoodLib.atualizarDadosEmpresa(
            this.empresaSelecionada.cod_empresa,
            loja.id,
            withDados ? dt : null
          );
          //console.log("auth/atualizaEmpresas", this.$store.state.auth.user);

          console.log("LOJA OK", res);
        } catch (err) {
          console.log("ERROR", err);
        }
        loja.loading = false;
      }
      await this.$store.dispatch(
        "auth/atualizaEmpresas",
        this.$store.state.auth.user
      );
    },
    async carrega() {
      console.log("empresa", this.empresaSelecionada);
      this.lista = JSON.parse(
        JSON.stringify(
          this.empresaSelecionada.integracoes.filter(
            (x) => x.Sistema == "IFood"
          )
        )
      );
      console.log("LISTA", this.lista);
      console.log("ATVIA", this.$store.state.empresa_ativa);
      for (let loja of this.lista) {
        let add = {};

        Object.keys(loja.Adicionais).forEach((k) => {
          add[loja.Adicionais[k].chave] = loja.Adicionais[k].valor;
        });
        loja.Adicionais = add;
        //loja.loading = false;
        // let dados = await IFoodLib.getDadosLoja(
        //   this.$store.state.empresa_ativa.cod_empresa,
        //   loja.id
        // );
        //console.log("dados", dados);/
      }
    },
    async changeEmpresa(empresa) {
      this.empresaSelecionada = empresa;
      this.carrega();
    },
    async solicitarChaveIfood() {
      this.loadingIfood = true;
      let res = await IFoodLib.solicitarCodigoAcessoIFOOD();
      console.log("RESULT", res);
      if (res && res.success == true) {
        if (res.data.verificationUrlComplete) {
          Object.keys(res.data).forEach((k) => {
            this.integracaoSelecioanda.Adicionais[k] = res.data[k];
          });

          this.integracaoSelecioanda.Adicionais.Solicitado = true;
          this.integracaoSelecioanda.Adicionais.autorizado = false

          window.open(
            res.data.verificationUrlComplete,
            "ifood",
            "width=600, height=600"
          );
        }
      }
    },

    async changeInfos(obj) {
      this.integracaoSelecioanda = obj;
      this.updateIntegrations();
    },

    async autorizarChaveIfood() {
      let erros = [];
      if (
        !this.integracaoSelecioanda.Login ||
        this.integracaoSelecioanda.Login == ""
      ) {
        erros.push("Chave de autorização não inserida ainda!");
      }
      if (
        !this.integracaoSelecioanda.Adicionais.authorizationCodeVerifier ||
        this.integracaoSelecioanda.Adicionais.authorizationCodeVerifier == ""
      ) {
        erros.push("Chave de autorização não inserida ainda!");
      }

      if (erros.length > 0) {
        this.$swal({
          title: "Atenção",
          html: erros.join("<hr/>"),
          icon: "error",
        });
      } else {
        this.loadingAutorizacaoIfood = true;
        let resultToken = await IFoodLib.getConfirmacaoAcesso(
          this.integracaoSelecioanda.Login,
          this.integracaoSelecioanda.Adicionais["authorizationCodeVerifier"]
        );
        if (resultToken && resultToken.success) {
          Object.keys(resultToken.data).forEach((k) => {
            this.integracaoSelecioanda.Adicionais[k] = resultToken.data[k];
          });

          this.integracaoSelecioanda.Adicionais.autorizado = true;
          this.integracaoSelecioanda.Adicionais.error = '';

          await this.updateIntegrations();
          this.$swal({
            title: "Sucesso",
            text: "Chave de autorização confirmada com sucesso!",
            icon: "success",
          });

          await this.$store.dispatch(
            "auth/atualizaEmpresas",
            this.$store.state.auth.user
          );

          await this.carrega();

          await this.atualizarDadosEmpresa();

          this.$bvModal.hide("modal-integracao");
        }

        this.loadingAutorizacaoIfood = false;
      }
    },
    async updateIntegrations() {
      let obj = {
        ...this.integracaoSelecioanda,
        Adicionais: Object.keys(this.integracaoSelecioanda.Adicionais).map(
          (k) => {
            return {
              chave: k,
              valor: this.integracaoSelecioanda.Adicionais[k],
            };
          }
        ),
      };
      console.log("OBJ", obj);
      let hasIntegracao = this.empresaSelecionada.integracoes.find(
        (x) => x.id == this.integracaoSelecioanda.id
      );
      if (!this.integracaoSelecioanda.id || !hasIntegracao) {
        this.empresaSelecionada.integracoes.push(obj);
        console.log("EMPRESA", this.empresaSelecionada);
        let resultUpdate = await IFoodLib.updateIntegration(
          this.empresaSelecionada.cod_empresa,
          0,
          obj
        );
        console.log("RESULT UPDATE", resultUpdate);
        if (resultUpdate && resultUpdate.success) {
          this.$swal({
            title: "Sucesso! Integração atualizada com sucesso!",
            showConfirmButton: false,
            icon: "success",
            toast: true,
            interval: 1000,
            position: "top-end",
            timer: 1000,
          });
        }
      } else {
        if (hasIntegracao) {
          hasIntegracao = obj;
        }
        let resultUpdate = await IFoodLib.updateIntegration(
          this.empresaSelecionada.cod_empresa,
          hasIntegracao.id,
          hasIntegracao
        );
        console.log("RESULT UPDATE 2", resultUpdate);
        if (resultUpdate && resultUpdate.success) {
          this.$swal({
            title: "Sucesso! Integração atualizada com sucesso!",
            showConfirmButton: false,
            icon: "success",
            toast: true,
            interval: 1000,
            position: "top-end",
            timer: 1000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btnAdd {
  background-color: #098205;
  color: white;
  border-radius: 10px;
  font-size: 20px;
  padding: 30px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    background-color: #0d9e0d;
  }
}
</style>
